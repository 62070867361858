import styled from "styled-components";

export const StyledPlaceholder = styled.span`
  position: absolute;
  left: 12px;
  transform: translateY(50%);
  color: ${(props) => props.color};

  transition: top 0.3s ease, font-size 0.3s ease;

  top: ${(props) => (props.isDirty ? "-25px" : "calc(-50% + 20px)")};
  font-size: ${(props) => (props.isDirty ? "10px" : "1em")};
`;

export const StyledTextField = styled.input`
  appearance: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => props.color};

  border: 1px solid ${(props) => props.color};
  border-radius: 13px;

  width: 100%;
  padding: 12px;
  font-size: clamp(0.2rem, calc(0.4rem + 0.6vmax + 0.3vw), 30rem);

  transition: border 0.3s ease;

  &:focus {
    border: 1px solid ${(props) => props.theme.colors.secondary};
  }

  &:focus + ${StyledPlaceholder} {
    top: -25px;
    font-size: 10px;
  }
`;

export const StyledLabel = styled.label`
  position: relative;
  margin-top: 10px;
  font-size: clamp(0.2rem, calc(0.4rem + 0.6vmax + 0.3vw), 30rem);
`;
