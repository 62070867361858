import styled from "styled-components";
import { mediaSizes } from "../../settings/theme.styles";
import { StyledParagraph } from "../paragraph/paragraph.styles";

export const StrategyInfoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  > ${StyledParagraph} {
    font-size: clamp(0.4rem, 2rem, 30rem);
    font-weight: 600;
    margin-top: 2rem;
    max-width: 50%;
  }
  @media (min-width: ${mediaSizes.md}) {
    flex-direction: row-reverse;
    > ${StyledParagraph} {
      padding-right: 5%;
      width: 50%;
      text-align: start;
    }
  }
`;

export const StrategyInfoImg = styled.img`
  width: clamp(300px, 54%, 65vw);
  height: auto;
  max-width: 45%;
  padding-left: 10%;
`;

export const StrategyMarkerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 4vh;

  @media (min-width: ${mediaSizes.md}) {
    display: none;
  }
`;
export const StrategyMarkerLine = styled.div`
  height: 120px;
  width: 5px;
  margin: 0;

  background: linear-gradient(
    0deg,
    ${(props) => props.theme.colors.secondary} 0%,
    ${(props) => props.theme.colors.primary} 100%
  );
`;
export const StrategyMarkerCircle = styled.div`
  margin: 0;
  width: 23px;
  height: 23px;
  border-radius: 50px;
  border: solid 4px ${(props) => props.theme.colors.secondary};

  background-color: ${(props) => props.theme.colors.primary};
`;
