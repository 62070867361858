import React from "react";
import { useTranslation } from "react-i18next";

import {
  SectionComponent,
  TitleComponent,
  ContactUsFormComponent,
  ParagraphComponent,
} from "../";

const ContactUsSectionComponent = () => {
  const { t } = useTranslation();

  const style = {
    marginBottom: "25px",
  };

  return (
    <SectionComponent zIndex={1} id={"section-contact-us"}>
      <TitleComponent id="contact_us" content={t("contact_us.title")} />
      <ParagraphComponent style={style}>
        {t("contact_us.email")}
      </ParagraphComponent>
      <ParagraphComponent style={style} fontWeight="400">
        {t("contact_us.bussinessCTA")}
      </ParagraphComponent>
      <ParagraphComponent style={style}>
        {t("contact_us.bussinessEmail")}
      </ParagraphComponent>
      <ParagraphComponent style={style} fontWeight="400">
        {t("contact_us.technicalCTA")}
      </ParagraphComponent>
      <ParagraphComponent>{t("contact_us.technicalEmail")}</ParagraphComponent>
      <ContactUsFormComponent />
    </SectionComponent>
  );
};

export default ContactUsSectionComponent;
